.ReactCollapse--collapse {
  transition: height 500ms;
}

.active-line-call {
  border: 2px #ff0000 solid;
  border-radius: 12px;

  animation: blink 1s;
  animation-iteration-count: 100;
}

.non-active-line-call {
  cursor: pointer;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  background: rgba(0, 0, 0, 0);
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-corner {
  display: none;
  background: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background: rgba(31, 35, 41, 0.3);
  width: 6px;
  border-radius: 3px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  display: block;
}

.ant-table {
  scrollbar-color: unset !important;
}

.ant-breadcrumb-link {
  text-decoration: none !important;
}

@keyframes blink {
  50% {
    border-color: #fff;
  }
}

.gjs-rte-action:has(.gjs-property-select) {
  width: fit-content !important;
}
